// Migrated
<template>
  <div
    id="charterButtonDate"
    ref="charterButtonDate"
    class="charter-search__btn"
    :aria-expanded="isActive"
    role="button"
    aria-haspopup="true"
    aria-labelledby="charterButtonLabelDate"
    @click="$emit('click')"
  >
    <div
      id="charterButtonLabelDate"
      class="charter-search__btn-title flex w-full"
    >
      <fa
        v-if="!$isSol"
        class="mr-2"
        :icon="icons.faCalendarAlt"
      />
      <span>
        {{ $t('charterSearchDate') }}
      </span>
      <fa
        v-if="$isSol"
        class="charter-search__btn-icon ml-auto transition-transform"
        icon="chevron-down"
        :style="{ transform: `rotateX(${isActive ? '180deg' : '0'})` }"
      />
    </div>
    <div class="charter-search__btn-text">
      <span v-if="lastminute">
        {{ $t('lastMinuteTitle') }}
      </span>
      <span v-else-if="!(selectedDateRangeStartDate || selectedDateRangeEndDate)">
        {{ $t('charterSearchSelect') }}
      </span>
      <span v-else>{{ localeDate(selectedDateRangeStartDate) }}<small
        v-if="!selectedDateRangeEndDate && selectedDateRangeDuration?.value === 'custom'"
        class="block"
      >{{ customRange }}</small>
        <small
          v-else-if="selectedDateRangeEndDate"
          class="block"
        >{{ $t('charterSearchDateUntil', { n: selectedDateRangeEndDate }) }}</small>
        <small
          v-else-if="selectedDateRangeDuration"
          class="block"
        >+{{ $t('charter.duration.week', Math.round(selectedDateRangeDuration / 7)) }}</small>
      </span>
      <fa
        v-if="!$isSol"
        class="charter-search__btn-icon"
        :icon="isActive ? 'chevron-up' : 'chevron-down'"
      />
    </div>
    <slot name="error-message" />
    <div
      v-if="selectedDateRangeStartDate && !selectedDateRangeEndDate && !selectedDateRangeDuration"
      class="charter-search__btn-title text-error blink blink-title"
    >
      {{ $t('charterLegendSelectFlightDates') }}
    </div>
  </div>
</template>

<script>
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons'
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { localeDate } = useDate()

    return {
      localeDate,
    }
  },

  name: 'PackageSearchDateRangeButton',

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  data () {
    return {
      icons: {
        faCalendarAlt,
      },
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, {
      selectedDateRangeStartDate: 'selectedDateRangeStartDate',
      selectedDateRangeEndDate: 'selectedDateRangeEndDate',
      selectedDateRangeDuration: 'selectedDateRangeDuration',
      lastminute: 'lastminute',
    }),

    customRange () {
      if (this.selectedDateRangeDuration?.value === 'custom' && this.selectedDateRangeDuration.custom?.length === 2) {
        const [endDateDurationStart, endDateDurationEnd] = this.selectedDateRangeDuration.custom
        return `+${endDateDurationStart} ${this.$t('to')} ${this.$t('charterSearchPeriodDay', endDateDurationEnd)}`
      }

      return null
    },
  },
})
</script>

<style lang="scss" scoped>

</style>
